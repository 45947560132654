


























































import { Component, Mixins } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import XForm from "../SimpleForm.vue";
import XButton from "../SimpleButton.vue";
import { passwordRecoveryForm } from "@/assets/scripts/form-fields/main";
const { values, attrs, validations, errors } = passwordRecoveryForm;
import AppApiMixin from "../mixins/AppApi.vue";
import {mapGetters} from "vuex";

@Component({
  components: {
    XForm,
    XButton,
  },
  mixins: [validationMixin],
  computed: {
    ...errors,
    ...mapGetters({
      branchesList: "branches/branchesList",
      branchesListFormatted: "branches/branchesListFormatted",
    }),
  },
  validations,
})
class PasswordRecoveryForm extends Mixins(XForm, AppApiMixin) {
  values = values;
  allFieldAttrs = attrs;
  submitSuccess = "";
  submitError = "";
  sent = false;

  useSubmitButtonLoading = false;

  mounted() {
    // список филиалов
    this.getBranches();
    // всегда более 1 филиала
    // if (this.branchesList.length <= 1) {
    //   delete this.allFieldAttrs['branch'];
    //   delete attrs['branch'];
    //   this.values['branch'] = this.branchesListFormatted[0] != undefined ? (this.branchesListFormatted[0] || "") : "";
    // }
  }

  private openSignInPage(): void {
    this.redirectToPage("signIn");
  }

  private async submitForm() {
    this.submitSuccess = "";
    this.submitError = "";
    this.useSubmitButtonLoading = true;
    const { username, branch } = this.getValues();

    const current_branch = this.branchesList.filter(_ => _.full_name == branch).map(_ => _.name)[0] || "";

    this.recoveryPassword({username, branch: current_branch})
      .then((response) => {
        this.submitSuccess = "Письмо с инструкциями отправлено на указанную электронную почту " + response;
        this.sent = true;
      })
      .catch((e) => {
        this.submitError = e;
      })
      .finally(() => {
        this.useSubmitButtonLoading = false;
      });
  }
}

export default PasswordRecoveryForm;
